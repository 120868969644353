import React from "react";

function NotFoundPage() {
  return (
    <div>
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  );
}

export default NotFoundPage;
